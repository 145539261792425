import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AcknowledgePolicyView from "../../../pages/acknowledgePolicy/AcknowledgePolicyView";
import { Header } from "./header/Header";

const AcknowledgePolicyViewLayout = () => {
  const [isActiveSidebar, setIsActiveSidebar] = useState(false);

  const toggleSidebarButton = () => {
    setIsActiveSidebar((current) => !current);
  };

  return (
    <main className="mainContentBox vh-100">
      <div className="d-flex flex-column h-100 w-100">
        <Header
          toggleSidebarButton={toggleSidebarButton}
          isActiveSidebar={isActiveSidebar}
        />
        <div className="flex-grow-1 overflow-hidden w-100 header-static-below-h-600">
          <Row className="gx-0 h-100">
            <Col xs className="d-flex flex-column mw-1 h-100 bg-body-tertiary">
              <AcknowledgePolicyView />
            </Col>
          </Row>
        </div>
      </div>
    </main>
  );
};

export default AcknowledgePolicyViewLayout;
