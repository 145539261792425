import { FC } from "react";
import { Alert, Button, Modal, Stack } from "react-bootstrap";
import { userPoliciesSnooze } from "../../../../redux/userPoliciesModule/userPoliciesModuleSlice";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";
import { baseRoutes } from "../../../../routes/base-routes";
import { MdWarning } from "react-icons/md";
import { createMarkup } from "../../../../helpers/helperMethods";
import Loader from "../../Loader";

interface IPolicyInfoModal {
  show?: boolean;
  policyData?: any;
  handleClose?: any;
}

const PolicyInfoModal: FC<IPolicyInfoModal> = ({
  show,
  policyData,
  handleClose,
}) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isLoading = useSelector((state: any) => state.userPoliciesSlice.loading);
  

  const snoozeHandler = () => {
    const ID = policyData?.id;
    dispatch(userPoliciesSnooze(ID)).then((response) => {
      if (response.payload.statusCode === 200) {
        handleClose();
      }
    })
  };

  return (
    <>
      <Loader isLoading={isLoading} classCustom={'theme-loader-z-index'} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
        scrollable={true}
        className="theme-modal"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title as="h6" className="fw-semibold">
            Action Required
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 min-h-100 d-flex flex-column ck-content">
          <div className="d-flex flex-column gap-3 h-100 overflow-auto">
            <div className="modal-policy-body flex-grow-1 overflow-auto">
              Please review the latest update on <span className="fw-bold">Policies</span>.
              <div dangerouslySetInnerHTML={createMarkup(policyData?.policy_message)} />
              <div>Document: {policyData?.code} - <span className="fw-bold">{policyData?.document_name}</span></div> 
            </div>
            <div className="modal-policy-footer text-center">
              <Alert
                className="p-0 mb-0 border-0 d-inline-flex justify-content-center align-items-center lh-sm"
                variant="link"
              >
                <span className="me-2 text-warning"><MdWarning size={20} /></span>
                <span className="text-start">This document must be read and acknowledged within 24 hours.</span>
              </Alert>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Stack direction="horizontal" gap={3} className="flex-wrap py-1">
              {
                policyData?.user_plcy_record && policyData?.user_plcy_record?.snoze_at ? "" :  
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={snoozeHandler}
                  className="min-w-80"
                >
                  Snooze for 24 hours
                </Button>
              }
            <Link
              to={`${baseRoutes.acknowledgeViewPolicy.path}?id=${policyData?.id}`}
              className="btn btn-primary min-w-80"
            >
              Read Document
            </Link>
          </Stack>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PolicyInfoModal;
