import { FC, useCallback, useState } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import Loader from './Loader';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'react-pdf/node_modules/pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

interface IPdfViewer {
    fileUrl: any;
}


const PdfViewer: FC<IPdfViewer> = ({ fileUrl }) => {
    const [numPages, setNumPages] = useState<number>();
    const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
    const [containerWidth, setContainerWidth] = useState<number>();

    const resizeObserverOptions = {};
    const maxWidth = 4000;

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    function onDocumentLoadSuccess({ numPages: nextNumPages }: any): void {
        setNumPages(nextNumPages);
    }

    return (
        <div ref={setContainerRef} className='w-100 h-100 pdf-viewer-cover'>
            <Document
                file={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
                loading={<Loader isLoading={true} />}
            >
                {Array?.from(new Array(numPages), (_el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                    />
                ))}
            </Document>
        </div>
    )
}

export default PdfViewer