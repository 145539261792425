import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, STEPS_DEFAULT_SORT_COLUMN, STEPS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';
const initialState = {
    loading: false,
    listObj: {
        deletedUserProp: false,
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STEPS_LIST_ROWS_DEF,
        sortFieldProp: STEPS_DEFAULT_SORT_COLUMN,
    }
};

export const userPoliciesListing = createAsyncThunk(
    "userPoliciesModule/userPoliciesListing",
    async (data: any) => {
        try {
            const response = await axiosRequest.post(`${EndPoint.USER_POLICIES_LIST}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const userListPoliciesBlock = createAsyncThunk(
    "userPoliciesModule/userListPoliciesBlock",
    async (data: any) => {
        try {
            const response = await axiosRequest.post(`${EndPoint.USER_POLICIES_BLOCK_LIST}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const acknowledgeUserPolicyView = createAsyncThunk(
    "userPoliciesModule/acknowledgeUserPolicyView",
    async (ID: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.USER_POLICIES_ACKNOWLEDGE_VIEW}/${ID}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const userPoliciesSnooze = createAsyncThunk(
    "userPoliciesModule/userPoliciesSnooze",
    async (ID: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.USER_POLICIES_SNOOZE}/${ID}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const acknowledgeUserPolicy = createAsyncThunk(
    "userPoliciesModule/acknowledgeUserPolicy",
    async (ID: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.USER_POLICIES_ACKNOWLEDGE}/${ID}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const getUserPolicyFoldersAction = createAsyncThunk(
  "userPoliciesModule/getUserPolicyFoldersAction",
  async () => {
    try {
      const response = await axiosRequest.get(
        `${EndPoint.USER_POLICIES_FOLDER_LIST_API}`
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
);



const userPoliciesModuleSlice = createSlice({
    name: 'User Policies',
    initialState: initialState,
    reducers: {
        changeListDataObj(state, actions) {
            // state.listObj.deletedUserProp = actions.payload.deletedUser;
            // state.listObj.searchKeyProp = actions.payload.searchKey;
            // state.listObj.sortOrderProp = actions.payload.sortOrder;
            // state.listObj.currentPageProp = actions.payload.currentPage;
            // state.listObj.rowsProp = actions.payload.rows;
            // state.listObj.sortFieldProp = actions.payload.sortField;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userPoliciesListing.pending, (state) => {
                state.loading = true;
            })
            .addCase(userPoliciesListing.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(userPoliciesListing.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(userListPoliciesBlock.pending, (state) => {
                state.loading = true;
            })
            .addCase(userListPoliciesBlock.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(userListPoliciesBlock.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(acknowledgeUserPolicyView.pending, (state) => {
                state.loading = true;
            })
            .addCase(acknowledgeUserPolicyView.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(acknowledgeUserPolicyView.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(userPoliciesSnooze.pending, (state) => {
                state.loading = true;
            })
            .addCase(userPoliciesSnooze.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(userPoliciesSnooze.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(acknowledgeUserPolicy.pending, (state) => {
                state.loading = true;
            })
            .addCase(acknowledgeUserPolicy.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(acknowledgeUserPolicy.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getUserPolicyFoldersAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserPolicyFoldersAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getUserPolicyFoldersAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const userPoliciesModuleActions = userPoliciesModuleSlice.actions;

export default userPoliciesModuleSlice.reducer;