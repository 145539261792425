import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import EndPoint from "../../api/Endpoint";
import {
  STAGES_LIST_ROWS_DEF,
  LIST_CRR_PAGE,
  DEF_SORT_ORDER,
  STAGES_DEFAULT_SORT_COLUMN,
} from "../../constants/global";
import { axiosRequest } from "../common-api/axios";

const initialState = {
  loading: false,
};

export const getKnowledgeBasePoliciesListAction = createAsyncThunk(
  "knowledgebasePolicyModule/getKnowledgeBasePoliciesListAction",
  async (payload: any) => {
    try {
      const response = await axiosRequest.post(EndPoint.KB_POLICIES_GET_API, payload);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const updateKnowledgeBasePolicyStatusAction = createAsyncThunk(
  "knowledgebasePolicyModule/updateKnowledgeBasePolicyStatusAction",
  async (payload: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.KB_POLICIES_STATUS_UPDATE_API, payload);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const addNewPolicyAction = createAsyncThunk(
  "knowledgebasePolicyModule/addNewPolicyAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.KB_POLICIES_ADD_API}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const editPolicyAction = createAsyncThunk(
  "knowledgebasePolicyModule/editPolicyAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.KB_POLICIES_EDIT_API}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const folderRenameEditAction = createAsyncThunk(
  "knowledgebasePolicyModule/folderRenameEditAction",
  async (payload: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.KB_POLICIES_FOLDER_RENAME_API}`, payload);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getPolicyFoldersAction = createAsyncThunk(
  "knowledgebasePolicyModule/getPolicyFoldersAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.KB_POLICIES_FOLDER_LIST_API}/${value}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getDesignationList = createAsyncThunk(
	"knowledgebasePolicyModule/getDesignationList",
	async (value: any, thunkAPI) => {
		try {
				const response = await axiosRequest.get(`${EndPoint.DESIGNATION_LIST}`);
				return response;
		} catch (error: any) {
				return error;
		}
	}
);

export const viewPolicyAction = createAsyncThunk(
  "knowledgebasePolicyModule/viewPolicyAction",
  async (ID:any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.KB_POLICIES_VIEW_API}/${ID}`);
      return response;
    } catch (error:any) {
        return error;
    }
  }
);

// Delete Document
export const deletePolicyDocumentAction = createAsyncThunk(
  "knowledgebasePolicyModule/deletePolicyDocumentAction",
  async (ID:any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.KB_POLICIES_DELETE_DOCUMENT_API}/${ID}`);
      return response;
    } catch (error:any) {
      return error;
    }
  }
);

// Delete Folder
export const deletePolicyFolderAction = createAsyncThunk(
  "knowledgebasePolicyModule/deletePolicyFolderAction",
  async (ID:any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.KB_POLICIES_DELETE_FOLDER_API}/${ID}`);
      return response;
    } catch (error:any) {
      return error;
    }
  }
);

export const getSharepointLinkAction = createAsyncThunk(
  "knowledgebasePolicyModule/getSharepointLinkAction",
  async (ID:any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.KB_POLICIES_SHAREPOINT_LINK_API}/${ID}`);
      return response;
    } catch (error:any) {
      return error;
    }
  }
);

//EXPORT
export const exportPoliciesAction = createAsyncThunk(
  "knowledgebasePolicyModule/exportPoliciesAction",
  async (payload: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.KB_POLICIES_DOWNLOAD_API}`, payload);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

const knowledgeBasePoliciesSlice: any = createSlice({
  name: "knowledgebase & stage",
  initialState: initialState,
  reducers: {
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getKnowledgeBasePoliciesListAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(getKnowledgeBasePoliciesListAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getKnowledgeBasePoliciesListAction.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(updateKnowledgeBasePolicyStatusAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateKnowledgeBasePolicyStatusAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(updateKnowledgeBasePolicyStatusAction.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(addNewPolicyAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(addNewPolicyAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(addNewPolicyAction.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(editPolicyAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(editPolicyAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(editPolicyAction.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(folderRenameEditAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(folderRenameEditAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(folderRenameEditAction.rejected, (state, action) => {
      state.loading = false;
    })
		.addCase(getPolicyFoldersAction.pending, (state) => {
			state.loading = true;
		})
		.addCase(getPolicyFoldersAction.fulfilled, (state, action) => {
			state.loading = false;
		})
		.addCase(getPolicyFoldersAction.rejected, (state, action) => {
			state.loading = false;
		})
		.addCase(getDesignationList.pending, (state) => {
			state.loading = true;
		})
		.addCase(getDesignationList.fulfilled, (state, action) => {
			state.loading = false;
		})
		.addCase(getDesignationList.rejected, (state, action) => {
			state.loading = false;
		})
    .addCase(deletePolicyDocumentAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(deletePolicyDocumentAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deletePolicyDocumentAction.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(deletePolicyFolderAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(deletePolicyFolderAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deletePolicyFolderAction.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getSharepointLinkAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(getSharepointLinkAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getSharepointLinkAction.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(exportPoliciesAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(exportPoliciesAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(exportPoliciesAction.rejected, (state, action) => {
      state.loading = false;
    })
  },
});

export const knowledgeBasePoliciesActions =
  knowledgeBasePoliciesSlice.actions;

export default knowledgeBasePoliciesSlice.reducer;
