/*!
This File contain all route that will use in ./src/index.js & in navigations
*/

import appConfig from "../helpers/config";

const basePath = appConfig.basePath;

export const baseRoutes = {
  login: {
    path: `${basePath}/login`,
    pathName: "Login",
  },
  forgotPassword: {
    path: `${basePath}/forgot-password`,
    pathName: "Forgot Password",
  },
  resetPassword: {
    path: `${basePath}/reset-password`,
    pathName: "Reset Password",
  },
  dashboard: {
    path: `${basePath}/dashboard`,
    pathName: "Dashboard",
  },
  clientManagement: {
    path: `${basePath}/client-management`,
    pathName: "Client Management",
  },
  caseManagement: {
    path: `${basePath}/case-management`,
    pathName: "Case Management",
  },
  manageCaseTypes: {
    path: `${basePath}/manage-case-types`,
    pathName: "Manage Case Types",
  },
  caseTypeDocumentsChecklists: {
    path: `${basePath}/case-type-documents-checklists`,
    pathName: "Case Type Documents Checklists",
  },
  caseTypeQuestionnaires: {
    path: `${basePath}/case-type-questionnaires`,
    pathName: "Case Type Questionnaires",
  },
  caseTypeStagesAndSteps: {
    path: `${basePath}/case-type-stages-and-steps`,
    pathName: "Case Type Stages And Steps",
  },
  caseTypeForms: {
    path: `${basePath}/case-type-forms`,
    pathName: "Case Type Forms",
  },
  knowledgeBaseStages: {
    path: `${basePath}/knowledge-base-stages`,
    pathName: "Knowledge Base Stages",
  },
  knowledgeBaseSteps: {
    path: `${basePath}/knowledge-base-steps`,
    pathName: "Knowledge Base Steps",
  },
  knowledgeChecklistItems: {
    path: `${basePath}/knowledge-base-doc-checklist-items`,
    pathName: "knowledge Base Doc Checklist Items",
  },
  knowledgeDocumentChecklist: {
    path: `${basePath}/knowledge-base-document-checklist`,
    pathName: "knowledge Base Document Checklist",
  },
  knowledgeDocumentTypes: {
    path: `${basePath}/knowledge-document-types`,
    pathName: "Knowledge Document Types",
  },
  knowledgeBaseDocuments: {
    path: `${basePath}/knowledge-base-documents`,
    pathName: "Knowledge Base Documents",
  },
  knowledgeBaseQuestions: {
    path: `${basePath}/knowledge-base-questions`,
    pathName: "Knowledge Base Questions",
  },
  knowledgeBaseQuestionnaires: {
    path: `${basePath}/knowledge-base-questionnaires`,
    pathName: "Knowledge Base Questionnaires",
  },
  knowledgeBaseForms: {
    path: `${basePath}/knowledge-base-forms`,
    pathName: "Knowledge Base Forms",
  },
  knowledgeBaseEmailTemplates: {
    path: `${basePath}/knowledge-base-email-templates`,
    pathName: "Knowledge Base Email Templates",
  },
  knowledgeBasePolicies: {
    path: `${basePath}/knowledge-base-policies`,
    pathName: "Knowledge Base Policies",
  },
  userManagement: {
    path: `${basePath}/user-management/*`,
    pathName: "User Management",
  },
  rolesAndPermissions: {
    path: `${basePath}/roles-and-permissions`,
    pathName: "Roles And Permissions",
  },
  manageTeams: {
    path: `${basePath}/manage-teams`,
    pathName: "Manage Teams",
  },
  designationManager: {
    path: `${basePath}/manage-designations`,
    pathName: "Manage Teams",
  },
  settings: {
    path: `${basePath}/settings`,
    pathName: "Settings",
  },
  reports: {
    path: `${basePath}/reports`,
    pathName: "Reports",
  },
  savedReports: {
    path: `${basePath}/saved-reports`,
    pathName: "Saved Reports",
  },
  generateReports: {
    path: `${basePath}/generate-new-report`,
    pathName: "Generate New Report",
  },
  companyManagement: {
    path: `${basePath}/company-management`,
    pathName: "Company Management",
  },
  notification: {
    path: `${basePath}/notification`,
    pathName: "Notification",
  },
  reminder: {
    path: `${basePath}/reminder`,
    pathName: "Reminder",
  },
  search: {
    path: `${basePath}/search`,
    pathName: "Global Search",
  },
  calendar: {
    path: `${basePath}/calendar`,
    pathName: "Calendar",
  },
  acknowledgeViewPolicy: {
    path: `${basePath}/acknowledge-policy`,
    pathName: "Acknowledge Policy View",
  },
  userPolicyList: {
    path: `${basePath}/policies`,
    pathName: "User Policy List",
  }
};
