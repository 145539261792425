import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { acknowledgeUserPolicy, acknowledgeUserPolicyView, userListPoliciesBlock } from "../../redux/userPoliciesModule/userPoliciesModuleSlice";
import { baseRoutes } from "../../routes/base-routes";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import PdfViewer from "../../components/common/PdfViewer";

function AcknowledgePolicyView() {
	const useParams = new URLSearchParams(document.location.search);
	const policyId = useParams.get("id");
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
	const [policyData, setPolicyData] = useState<any>({});
	const navigate = useNavigate();

	useEffect(() => {
		if (policyId) {
			dispatch(acknowledgeUserPolicyView(policyId)).then((response) => {
				if (response.payload.statusCode === 200) {
					setPolicyData(response.payload.data);
				}
			});
		}
	}, [policyId])

	const handleAcknowledge = () => {
		dispatch(acknowledgeUserPolicy(policyId)).then((response) => {
			if (response.payload.statusCode === 200) {
				navigate(baseRoutes.dashboard.path);
				listPolicyModalData();
			}
		});
	}

	const listPolicyModalData = () => {
		const data: any = {}
		dispatch(userListPoliciesBlock(data)).then((response) => {
			if (response.payload.statusCode === 200) {
				if (response.payload.data?.length > 0) {
					setPolicyData(response.payload.data)
				}
			}
		});
	}

	// Function to get the file extension
	const getFileExtension = (url: string) => {
		if(policyData?.fetchSharepointFileContent?.file?.mimeType === "application/pdf"){
			return "pdf";
		}else{
			return url?.split('.')?.pop()?.toLowerCase();
		}
	};

	// Determine the file type
	const fileExtension = getFileExtension(policyData?.policyData?.sharepoint_file_info?.name);

	const fileUrl = policyData?.fetchSharepointFileContent?.["@microsoft.graph.downloadUrl"];

	return (
		<div className="p-3 h-100">
			<Card className="h-100 bg-white shadow-lg border-0 theme-card-cover d-flex flex-column">
				<div className="policy-page-header fs-5 fw-semibold px-3 pt-3">
					<Link target="_blank" to={policyData?.previewLink?.getUrl}>{policyData?.policyData?.sharepoint_file_info?.name}</Link>
				</div>
				<div className="policy-page-body flex-grow-1 overflow-auto p-3 position-relative">
					{fileExtension === 'pdf' ? (
						<PdfViewer fileUrl={fileUrl} />
					) : (
						<div className="h-100 w-100 overflow-auto">
							<iframe
								src={policyData?.previewLink?.getUrl}
								title={`Policy Document: ${policyData?.policyData?.sharepoint_file_info?.name || 'Document'}`}
								allowFullScreen={true}
								width={'100%'}
								height={'100%'}
								className="border-0"
							/>
						</div>
					)}
				</div>
				<div className="policy-page-footer mt-auto text-end p-3 border-top">
					{
						policyData?.userPolicyListing?.acknowledge ? "" : 
						<Button
							type="submit"
							variant="primary"
							onClick={handleAcknowledge}
							className="min-w-191"
							size="lg"
						>
							Acknowledge
						</Button>
					}
				</div>
			</Card>
		</div>
	);
}

export default AcknowledgePolicyView;