import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import MainLayout from "../components/common/layouts/MainLayout";
import appConfig from "../helpers/config";
import { lazyImport } from "../helpers/lazyImport";
import { baseRoutes } from "./base-routes";
import MainLayoutNotFound from "../components/common/layouts/MainLayoutNotFound";
import AcknowledgePolicyViewLayout from "../components/common/layouts/AcknowledgePolicyViewLayout";
const VerifyEmailUser = lazy(
  () => import("../pages/dashboard/verifyEmailUser")
);
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Profile = lazy(() => import("../pages/profile/Profile"));
const GlobalSearch = lazy(() => import("../pages/globalSearch/GlobalSearch"));
const Calendar = lazy(() => import("../pages/calendar"));

// Use the basePath variable in your TypeScript code
const basePath = appConfig.basePath;
const { UserManagement } = lazyImport(
  () => import("../pages/userManagement/routes"),
  "UserManagement"
);
const { RolesAndPermissions } = lazyImport(
  () => import("../pages/rolesAndPermissions/routes"),
  "RolesAndPermissions"
);
const { ManageTeams } = lazyImport(
  () => import("../pages/manageTeams/routes"),
  "ManageTeams"
);
const { DesignationManager } = lazyImport(
  () => import("../pages/DesignationManager/routes"),
  "DesignationManager"
);
const { ClientManagement } = lazyImport(
  () => import("../pages/clientManagement/routes"),
  "ClientManagement"
);
const { KnowledgeBaseStages } = lazyImport(
  () => import("../pages/knowledgeBase/stages/routes"),
  "KnowledgeBaseStages"
);
const { KnowledgeBaseSteps } = lazyImport(
  () => import("../pages/knowledgeBase/steps/routes"),
  "KnowledgeBaseSteps"
);
const { KbDocChecklistItems } = lazyImport(
  () => import("../pages/knowledgeBase/docChecklistItems/routes"),
  "KbDocChecklistItems"
);
const { KbDocumentChecklist } = lazyImport(
  () => import("../pages/knowledgeBase/documentChecklist/routes"),
  "KbDocumentChecklist"
);
const { KbDocumentTypes } = lazyImport(
  () => import("../pages/knowledgeBase/documentTypes/routes"),
  "KbDocumentTypes"
);
const { KbDocuments } = lazyImport(
  () => import("../pages/knowledgeBase/documents/routes"),
  "KbDocuments"
);
const { KbQuestions } = lazyImport(
  () => import("../pages/knowledgeBase/questions/routes"),
  "KbQuestions"
);
const { KbQuestionnaires } = lazyImport(
  () => import("../pages/knowledgeBase/questionnaires/routes"),
  "KbQuestionnaires"
);

const { CtmManageCaseTypes } = lazyImport(
  () => import("../pages/caseTypeManagement/manageCaseTypes/routes"),
  "CtmManageCaseTypes"
);
const { CtmForm } = lazyImport(
  () => import("../pages/caseTypeManagement/forms/routes"),
  "CtmForm"
);
const { CtmDocumentsChecklists } = lazyImport(
  () => import("../pages/caseTypeManagement/documentsChecklists/routes"),
  "CtmDocumentsChecklists"
);
const { CtmQuestionaries } = lazyImport(
  () => import("../pages/caseTypeManagement/questionnaires/routes"),
  "CtmQuestionaries"
);
const { CtmStagesAndSteps } = lazyImport(
  () => import("../pages/caseTypeManagement/stagesAndSteps/routes"),
  "CtmStagesAndSteps"
);

const { KbForms } = lazyImport(
  () => import("../pages/knowledgeBase/forms/routes"),
  "KbForms"
);
const { KbEmailTemplate } = lazyImport(
  () => import("../pages/knowledgeBase/emailTemplate/routes"),
  "KbEmailTemplate"
);
const { KbPolicies } = lazyImport(
  () => import("../pages/knowledgeBase/policies/routes"),
  "KbPolicies"
);

const { CaseManagement } = lazyImport(
  () => import("../pages/caseManagement/routes"),
  "CaseManagement"
);

const { Notification } = lazyImport(
  () => import("../pages/notification/routes"),
  "Notification"
);

const { Reminder } = lazyImport(
  () => import("../pages/reminder/routes"),
  "Reminder"
);

const { SavedReports } = lazyImport(
  () => import("../pages/reports/savedReports/routes"),
  "SavedReports"
);
const { GenerateNewReport } = lazyImport(
  () => import("../pages/reports/generateNewReport/routes"),
  "GenerateNewReport"
);

const { Settings } = lazyImport(
  () => import("../pages/settings/routes"),
  "Settings"
);

const { CompanyManagement } = lazyImport(
  () => import("../pages/companyManagement/routes"),
  "CompanyManagement"
);

const { UserPolicyModule } = lazyImport(
  () => import("../pages/policy/routes"),
  "UserPolicyModule"
);

const idRegex = '*';//'([0-9a-zA-Z]*)';
/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 *
 */
export const protectedRoutes: RouteObject[] = [
  {
    path: "",
    element: <VerifyEmailUser />,
  },

  {
    path: "/",
    element: <VerifyEmailUser />,
  },

  {
    path: appConfig.basePath,
    element: <MainLayout />,
    children: [
      { path: "", element: <VerifyEmailUser /> },
      { path: baseRoutes.dashboard.path, element: <Dashboard /> },
      { path: baseRoutes.userManagement.path, element: <UserManagement /> },
      { path: "profile", element: <Profile /> },
      { path: "roles-and-permissions/*", element: <RolesAndPermissions /> },
      { path: "manage-teams/*", element: <ManageTeams /> },
      { path: "manage-designations/*", element: <DesignationManager /> },
      { path: "client-management/*", element: <ClientManagement /> },
      { path: "knowledge-base-stages/*", element: <KnowledgeBaseStages /> },
      { path: "knowledge-base-steps/*", element: <KnowledgeBaseSteps /> },
      {
        path: "knowledge-base-doc-checklist-items/*",
        element: <KbDocChecklistItems />,
      },
      {
        path: "knowledge-base-document-checklist/*",
        element: <KbDocumentChecklist />,
      },
      { path: "knowledge-document-types/*", element: <KbDocumentTypes /> },
      { path: "knowledge-base-documents/*", element: <KbDocuments /> },
      { path: "knowledge-base-questions/*", element: <KbQuestions /> },
      {
        path: "knowledge-base-questionnaires/*",
        element: <KbQuestionnaires />,
      },
      { path: "manage-case-types/*", element: <CtmManageCaseTypes /> },
      { path: "case-type-forms/*", element: <CtmForm /> },
      {
        path: "case-type-documents-checklists/*",
        element: <CtmDocumentsChecklists />,
      },
      { path: "case-type-questionnaires/*", element: <CtmQuestionaries /> },
      { path: "case-type-stages-and-steps/*", element: <CtmStagesAndSteps /> },
      { path: "knowledge-base-forms/*", element: <KbForms /> },
      {
        path: "knowledge-base-email-templates/*",
        element: <KbEmailTemplate />,
      },
      { path: "knowledge-base-policies/*", element: <KbPolicies /> },
      { path: "case-management/*", element: <CaseManagement /> },
      { path: `${baseRoutes.settings.path}/*`, element: <Settings /> },
      { path: baseRoutes.notification.path, element: <Notification /> },
      { path: baseRoutes.reminder.path, element: <Reminder /> },
      { path: baseRoutes.search.path, element: <GlobalSearch /> },
      { path: "saved-reports/*", element: <SavedReports /> },
      { path: "generate-new-report/*", element: <GenerateNewReport /> },
      { path: baseRoutes.calendar.path, element: <Calendar /> },
      { path: "company-management/*", element: <CompanyManagement /> },
      { path: "policies/*", element: <UserPolicyModule /> },
      
    ],
  },
  {
    path: baseRoutes.acknowledgeViewPolicy.path, 
    element: <AcknowledgePolicyViewLayout />,
  },
  {
    path: `/app/${idRegex}`,
    element: <MainLayoutNotFound />,
  },
  {
    path: `/${idRegex}`,
    element: <MainLayoutNotFound />,
  }
];
