import moment from "moment";
import { AOS_FOLDERS, CP_Folders, E2, EB1, EB1A, EB1B, EB2_NIW_FOLDERS, EB5, EMAIL_REGEX, MANDAMUS } from "../constants/global";

/**
 * Finding Duplicate Value in Array
 * @date 04/10/2023 - 16:01:38
 *
 * @param {*} arr
 * @param {string} key
 * @param {*} name
 * @returns {*}
 */
export const findKeyInArray = (arr: any, key: string, name: any) => {
  const found = arr.some((el: any) => el[key] === name);
  return found;
};

/**
 * Downloading CSV file 
 * @date 04/10/2023 - 16:01:38
 *
 * @param {*} resp
 * @param {string} fileName
 * @returns {*}
 */

export const DownloadExcelHelperMethod = (resp: any, fileName: string) => {

  let data = resp.payload.data ? resp.payload.data : null
  if (!data) {
    return
  }

  if (data !== null || data !== undefined) {
    let filedata = new Blob([data], { type: 'text/csv; charset=utf-8' });
    let csvURL = window.URL.createObjectURL(filedata);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${fileName}.csv`);
    tempLink.click();
  }

}

/**
 * Creating Sanitize Markup
 *
 * @param {*} arr
 * @param {string} html
 * @returns {*}
 */

export const createMarkup = (html: any) => {
  return { __html: html };
}


/**
 * Time Format for Mails
 *
 * @param {*} arr
 * @param {string} dateTimeString
 * @returns {*}
 */
export const formatDateTimeInEmails = (dateTimeString: any) => {
  const now = moment();
  const dateTime = moment(dateTimeString);
  if (now.isSame(dateTime, "day")) {
    return dateTime.fromNow(); // Format for today's date
  }
  return dateTime.format("MM/DD/YYYY");
};



/**
 * First letter Capital of string or first name or last name
 *
 * @param {*} arr
 * @param {string} str
 * @returns {*}
 */
export const titleFirstLetterUpperCase = (str: any) => {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}


/**
 * Get First Date of any Month
 *
 * @param {date} date
 * @returns {date}
 */
export const firstDateOfMonth = (date = new Date()) => new Date(date.getFullYear(), date.getMonth(), 1);


/**
 * Get First Date of the Current Week
 *
 * @returns {date}
 */
export const getFirstDateOfCurrentWeek = () => {
  const today = moment(); // Get the current date
  const firstDateOfWeek = today.startOf('week'); // Start of the week (Sunday)
  return firstDateOfWeek.format('YYYY-MM-DD');
}


export const validateEmails = (emails:any) => {
  // Loop through the array and validate each email
  for (let email of emails) {
      if (!EMAIL_REGEX.test(email)) {
          return false; // Return false if any email is invalid
      }
  }
  return true; // Return true if all emails are valid
}

// Sort the array in ascending order
export const sortAndRemoveDuplicates = (options: { label: string, value: string }[]) => {
  const uniqueValues = new Set<string>();
  const uniqueOptions = options?.filter(option => {
    if (!uniqueValues.has(option?.value)) {
      uniqueValues.add(option?.value);
      return true;
    }
    return false;
  });

  return uniqueOptions.sort((a, b) => {
    const numA = parseInt(a?.value?.replace('+', ''), 10);
    const numB = parseInt(b?.value?.replace('+', ''), 10);
    return numA - numB;
  });
};

// CK Editor Content with Customization
export const processHTMLContent = (bodyMessageHTML: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(bodyMessageHTML, 'text/html');

  const childImage = doc.querySelectorAll('figure') ?? [];
  
  childImage.forEach(element => {
      const childInnerImage = element.querySelector('img');
      const widthValue = element.getAttribute('style')?.match(/width:\s*(\d+)px/);
      const width = widthValue ? parseInt(widthValue[1], 10) : null;

      if (width && childInnerImage) {
          childInnerImage.setAttribute('width', `${width}`);
          childInnerImage.setAttribute('height', 'auto');
          childInnerImage.style.height = 'auto';
      }

      console.log('width', width);
      console.log('childInnerImage', childInnerImage);

      element.style.margin = '0';
  });

  return doc.body.innerHTML;
};