import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../../auth';
import roleSlice from '../../../redux/rolePermissionModule/roleSlice';
import userMgtSlice from '../../../redux/userManagementModule/userMgtSlice';
import authSlice from '../../../redux/auth/authSlice';
import teamsSlice from '../../../redux/teamsModule/teamsSlice';
import knowledgeBaseSlice from '../../../redux/knowledgeBaseStageModule/knowledgeBaseSlice';
import stepsSlice from '../../../redux/knowledgeBaseStepsModule/stepsSlice';
import knowledgeBaseDocChkItemsSlice from '../../../redux/knowledgeBaseDocChecklistItemModule/DocChecklistSlice';
import { PURGE } from "redux-persist";
import clientSlice from '../../../redux/ClientManagementModule/clientSlice';
import knowledgeBaseDocumentCheckSlice from '../../../redux/knowledgeBaseDocChecklistModule/DocumentChecklistSlice';
import knowledgeBaseDocumentTypeSlice from '../../../redux/knowledgeBaseDocTypesModule/DocumentTypesSlice';
import questionsSlice from '../../../redux/questionsModule/questionsSlice';
import questionairreSlice from '../../../redux/questionairreModule/questionairreSlice';
import caseTypesSlice from '../../../redux/caseTypesModule/caseTypesSlice';
import designationManagerSlice from '../../../redux/designationManagerModule/designationManagerSlice';
import knowledgeBaseFormSlice from '../../../redux/KBFormsModule/KBFormsSlice';
import knowledgeBaseDocumentsSlice from '../../../redux/knowledgeBaseDocumentsModule/DocumentsSlice';
import knowledgeBaseEmailTemplateSlice from '../../../redux/knowledgeBaseEmailTemplateModule/EmailTemplateSlice';
import composeEmailSlice from '../../../redux/ClientManagementModule/composeEmailSlice';
import notesSlice from '../../../redux/ClientManagementModule/notesSlice';
import draftEmailSlice from '../../../redux/ClientManagementModule/draftEmailSlice';
import emailsSlice from '../../../redux/ClientManagementModule/emailsSlice';
import alertsSlice from '../../../redux/ClientManagementModule/alertsSlice';
import clientCaseSlice from '../../../redux/ClientManagementModule/clientCaseSlice';
import caseMgtSlice from '../../../redux/caseModule/caseMgtSlice';
import settingsSlice from '../../../redux/settingsModule/settingsSlice';
import notificationSlice from '../../../redux/notificationModule/notificationSlice';
import reportSlice from '../../../redux/reportsModule/reportSlice';
import savedReportSlice from '../../../redux/reportsModule/savedReportSlice';
import reminderSlice from '../../../redux/reminderModule/reminderSlice';
import dashboardSlice from '../../../redux/dashboard/dashboardSlice';
import calendarSlice from '../../../redux/calendar/calendarSlice';
import globalSearchSlice from '../../../redux/globalSearch/globalSearchSlice';
import companyManagementSlice from '../../../redux/companyManagementModule/companyManagementSlice';
import companyAlertsSlice from '../../../redux/companyManagementModule/alertsSlice';
import companyAllCasesSlice from '../../../redux/companyManagementModule/allCasesSlice';
import knowledgeBasePoliciesSlice from '../../../redux/knowledgeBasePoliciesModule/PoliciesSlice'
import userPoliciesModuleSlice from '../../../redux/userPoliciesModule/userPoliciesModuleSlice';


const MainReducer = combineReducers(
  {
    auth: authReducer,
    rolesSlice: roleSlice,
    usersMgtSlice: userMgtSlice,
    authSlice: authSlice,
    teamsSlice: teamsSlice,
    kbStageSlice: knowledgeBaseSlice,
    stepsSlice: stepsSlice,
    kbDocChecklistItems: knowledgeBaseDocChkItemsSlice,
    clientSlice: clientSlice,
    kbDocumentChecklists: knowledgeBaseDocumentCheckSlice,
    kbDocumentTypesItems: knowledgeBaseDocumentTypeSlice,
    questionsSlice: questionsSlice,
    questionairreSlice: questionairreSlice,
    caseTypesSlice: caseTypesSlice,
    designationManager: designationManagerSlice,
    KBFormSlice: knowledgeBaseFormSlice,
    KbDocuments: knowledgeBaseDocumentsSlice,
    kbEmailTemplates: knowledgeBaseEmailTemplateSlice,
    composeEmailSlice: composeEmailSlice,
    notesSlice: notesSlice,
    draftEmailSlice: draftEmailSlice,
    emailsSlice: emailsSlice,
    alertsSlice: alertsSlice,
    clientCaseSlice: clientCaseSlice,
    caseMgtSlice: caseMgtSlice,
    settingsSlice: settingsSlice,
    notificationSlice: notificationSlice,
    reportSlice: reportSlice,
    savedReportSlice: savedReportSlice, 
    reminderSlice: reminderSlice,
    dashboardSlice: dashboardSlice,
    calendarSlice: calendarSlice,
    globalSearchSlice: globalSearchSlice,
    companyManagementSlice: companyManagementSlice,
    companyAlertsSlice: companyAlertsSlice,
    companyAllCasesSlice: companyAllCasesSlice,
    KbPolicies: knowledgeBasePoliciesSlice,
    userPoliciesSlice: userPoliciesModuleSlice,
  }
);

const RootReducer = (state: any, action: any) => {
  if (action.type === "authentication/logout") {
    state = undefined
  }
  if (action.type === PURGE) {
    state = undefined
  }
  return MainReducer(state, action);
}
export default RootReducer;
